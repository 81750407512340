.resources_page {
    background-color: white;
    min-width: 850px;
    width: 75%;
    max-width: 1000px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    padding: 30px 30px;
}

.section_header {
    display: flex;
    font-size: 0.95em;
    text-transform: uppercase;
    border-bottom: 3px solid black;
}

.section_header div {
    margin-top: auto;
    margin-bottom: 10px;
    margin-left: 10px;
}

.resources {
    display: block;
    justify-content: space-between;
    /* gap: 30px; */
    margin: 20px 20px;
    max-width: 1000px;
}

.resource_item {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
}

.resource_item .headline {
    font-weight: 700;
}

.resource_item .blurb {
    margin: 20px 20px;
}

.resource_item img {
    margin: 20px 0px;
    width: 125px;
    height: 125px;
    object-fit: cover;
}