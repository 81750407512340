.about_page {
    background-color: white;
    min-width: 850px;
    width: 75%;
    max-width: 1000px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
}

.accent_bar {
    height: 25px;
    width: 100%;
    background-color: white;
}

.about_header {
    margin: 0px 30px;
    display: flex;
    font-size: 0.95em;
    text-transform: uppercase;
    border-bottom: 3px solid black;
}

.about_header div {
    margin-top: auto;
    margin-bottom: 10px;
    margin-left: 10px;
}

.about_content {
    justify-content: space-between;
    gap: 30px;
    text-align: left;
    margin: 20px 80px;
}

.images {
    margin: 20px 30px;
    text-align: center
}

.images img {
    padding: 10px;
    min-height: 10px;
    max-height: 50px;
    display: inline-block;
}