.calendar_page {
    background-color: white;
    width: 75%;
    min-width: 850px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accent_bar {
    height: 25px;
    width: 100%;
    background-color: #1ABFDB;
    margin-bottom: 20px;
}

.loading {
    position: absolute;
    right: 40%;
    top: 50%;
    font-size: 2em;
    font-weight: 200;
}

.cal {
    margin: 25px 0px;
}

.calendar_page button {
    cursor: pointer;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    background-color: #17B1D4;
    padding: 10px 20px;
    border-color: transparent;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
}

.calendar_page button:hover {
    background-color: #169FBD;
    box-shadow: 0px 4px 7px rgb(0 0 0 / 40%);
}