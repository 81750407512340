.header {
    display: flex;
    background-color: #1d95bc;
    height: 60px;
    line-height: 60px;
    font-family: Raleway;
    letter-spacing: 0.8px;
    font-size: 0.88em;
    color: white;
}

.header .logo_image {
    max-height: 60px;
    padding: 0px 20px 0px 20px;
}

.header_links {
    display: flex;
}

.header_links a {
    display: inline-block;
    padding: 0px 20px;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    overflow: hidden;
}

.header_links a:hover {
    background-color: #207BA7;
}

.header_links a.active {
    background-color: #236092;
}

.right_side {
    margin-left: auto;
}

.language img {
    padding-right: 12px;
}

.language {
    height: 100%;
    padding-left: 15px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    background-color: #1F88B2
}

.language select {
    font-size: 15px;
    font-weight: 500;
    background-color: #1F88B2;
    padding: 10px 0px;
    color: white;
    border: none;
}

.language select:focus {
    outline: none !important;
}