body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2d6694;
  background-image: url('images/bg.jpg')
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-light-webfont.woff2') format('woff2'),
    url('fonts/raleway-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-lightitalic-webfont.woff2') format('woff2'),
    url('fonts/raleway-lightitalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-regular-webfont.woff2') format('woff2'),
    url('fonts/raleway-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-italic-webfont.woff2') format('woff2'),
    url('fonts/raleway-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-medium-webfont.woff2') format('woff2'),
    url('fonts/raleway-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-bold-webfont.woff2') format('woff2'),
    url('fonts/raleway-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-mediumitalic-webfont.woff2') format('woff2'),
    url('fonts/raleway-mediumitalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-bolditalic-webfont.woff2') format('woff2'),
    url('fonts/raleway-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-black-webfont.woff2') format('woff2'),
    url('fonts/raleway-black-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/raleway-blackitalic-webfont.woff2') format('woff2'),
    url('fonts/raleway-blackitalic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}